@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #000;
  color: #154ba0;
}

a {
  text-decoration: none;
  transition: all 0.5s ease;
  font-weight: 500;
  color: #4bc9f0;

  &:hover {
    color: lighten($color: #b1ecfe, $amount: 1);
  }
}

p {
  margin-top: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 700;
  line-height: 1.5;
}

h3 {
  font-size: 20px;
}

.mb-2 {
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: #154ba0;
}

.text-secondary {
  color: #099b91;
}

.w-600 {
  font-weight: 600;
}

a.text-primary:hover {
  color: #154ba0;
  text-decoration: underline;
}

.euiButton {
  border: 0;
  border-radius: 100px;
  height: auto;
  padding: 5px 20px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  transition: all 0.5s ease;
  background-color: #154ba0;

  &:hover,
  &:focus {
    transform: none !important;
    text-decoration: none !important;
  }

  .euiButton__content {
    padding: 0;
  }

  &.euiButton-isDisabled a {
    color: #515761;
  }
}

.euiButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  &.euiButtonContent--iconLeft,
  &.euiButtonContent--iconRight {
    height: auto;
    justify-content: space-between;
  }
}

.euiButton--primary {
  color: #154ba0;
  background-color: rgba($color: #e2007a, $alpha: 0.2);

  a {
    color: #154ba0;
  }

  &:not([class*='isDisabled']):hover,
  &:not([class*='isDisabled']):focus {
    color: #fff;
    a {
      color: #fff;
    }
  }

  &.euiButton--fill:not([class*='isDisabled']),
  &.euiButton--fill:not([class*='isDisabled']):hover,
  &.euiButton--fill:not([class*='isDisabled']):focus {
    color: #fff;
    background-color: #13599d;
    border: 1px solid #fff;

    a {
      color: #fff;
    }
  }
}

.euiButtonEmpty {
  font-family: Poppins, sans-serif;
}

.euiButtonEmpty--primary {
  color: #4bc9f0;
}

.euiButtonEmpty--primary:enabled:focus {
  color: #fff;
  background-color: rgba($color: #e2007a, $alpha: 0.2);
}

.euiFlyout {
  background-color: #1f1f1f;
}

.euiModal {
  background-color: #fff;
  border-radius: 8px;
  min-width: 570px;

  @media (min-width: 48rem) {
    .euiFormControlLayout {
      min-width: 570px;
      border: none;
    }

    .euiFieldSearch {
      max-width: 570px;
    }
  }
}

.euiModalHeader {
  padding: 8px 8px 0;
  background-color: #727eaa;
  border-radius: 8px 8px 0 0;
}

.euiModalBody {
  margin-top: 1rem;
  padding: 0 8px 8px 8px;
  width: auto;

  .euiModalBody__overflow {
    padding: 0;
    mask-image: none;
  }

  &.no-overflow .euiModalBody__overflow {
    overflow: initial;
  }
}

@media (min-width: 768px) {
  .euiModalHeader {
    padding: 30px 30px 0;
  }
  .euiModalBody {
    padding: 0 30px 30px 30px;
    width: auto;
  }
}

.euiModalHeader__title h1 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
}

.euiModalBody:last-of-type .euiModalBody__overflow {
  padding: 0;
}

.euiModal__closeIcon {
  background-color: #727eaa;
}

.euiFieldSearch {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
  border: 1px solid #dadfee;
  border-radius: 8px;
  height: 48px;
  max-width: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;

  &:focus {
    background-image: none;
  }

  &::-webkit-input-placeholder {
    color: #353945;
  }

  &:-ms-input-placeholder {
    color: #353945;
  }

  &::placeholder {
    color: #353945;
  }

  + .euiFormControlLayoutIcons {
    color: #fff;
    height: 46px;
  }
}

.euiFormControlLayoutClearButton {
  padding: 0;
  border: 0;
}

.euiStepsHorizontal {
  width: 100%;
}

.euiStepHorizontal {
  background-color: transparent;
  border: none;
}

.euiStepNumber {
  background-color: #154ba0;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  transition: all 0.5s ease;

  &.euiStepNumber--incomplete {
    border-color: transparent;
    line-height: 32px;
    background-color: #fff;
    border-width: 1px;
    color: #154ba0;

    .euiStepNumber__number {
      top: 0;
    }
  }

  &.euiStepNumber--disabled {
    background-color: #fff;
    border-color: transparent;
    color: #154ba0;
    border: 1px solid #154ba0;
  }
}

.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__number:not(:focus-visible) {
  outline: none;
}

.euiStepNumber--complete {
  background-color: #154ba0;
  color: #fff;

  .euiStepNumber__icon {
    width: 20px;
    height: 20px;
  }
}

.euiStepHorizontal {
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  &::before,
  &::after {
    background-color: #fff;
  }
}

.euiStepHorizontal-isComplete {
  &::before,
  &::after {
    background-color: #fff;
  }
}

.euiStepHorizontal-isSelected {
  &::before {
    background-color: #fff;
  }
}

.euiStepHorizontal-isIncomplete {
  .euiStepNumber:hover {
    color: #154ba0;
    border-color: #fff !important;
  }
}

.euiStepHorizontal__title {
  display: none;
}

.euiFormControlLayout {
  height: 64px;
  box-shadow: none;
  background-color: #f1f2f6;
  border: 0;
  border-radius: 8px;
  width: 100%;
  max-width: none;
  border: 0.1px solid #dadfee;

  .euiFormControlLayout__childrenWrapper {
    height: 100%;

    .euiFieldText {
      height: 100%;
    }
  }

  .euiFieldText,
  .euiFieldNumber {
    background-image: none;
    background-color: #f1f2f6;
    box-shadow: none;
    width: 100%;
    max-width: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;

    &:focus {
      background-image: none;
    }

    &::-webkit-input-placeholder {
      color: #353945;
    }

    &:-ms-input-placeholder {
      color: #353945;
    }

    &::placeholder {
      color: #353945;
    }
  }

  .euiButtonEmpty--primary {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #4bc9f0;
  }
}

.euiButtonEmpty:hover {
  text-decoration: none !important;
}

.slick-dots {
  li {
    width: 0.5rem;
    height: 0.5rem;
  }

  li button {
    padding: 0;
    width: 0.5rem;
    height: 0.5rem;
    text-align: center;

    &::before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 1rem;
      background-color: #fff;
      transition: all 0.5s ease;
    }
  }

  li.slick-active {
    width: 1.5rem;

    button {
      width: 1.5rem;
    }

    button::before {
      width: 1.5rem;
      background-color: #e2007a;
    }
  }
}

.euiTabs {
  background-color: #154ba0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  padding-top: 1.5rem;
}

.euiTabs--bottomBorder {
  box-shadow: none;

  @media (min-width: 48rem) {
    // justify-content: flex-start;
  }
}

.euiTab {
  background-color: #4364a5;
  border: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 1rem;
  min-width: 0;
  justify-content: center;
  transition: all 0.5s ease;

  @media (min-width: 48rem) {
    min-width: 150px;
  }

  .euiTab__content {
    font-family: Poppins, sans-serif;
    line-height: 1.5;
    color: #fff;
    transition: all 0.5s ease;
  }

  &:not(.euiTab-isDisabled):hover,
  &:not(.euiTab-isDisabled):focus {
    background-color: rgba($color: #fff, $alpha: 1);

    .euiTab__content {
      text-decoration: none;
      color: #154ba0;
    }
  }

  &:not(.euiTab-isDisabled):focus {
    background-color: #fff;

    .euiTab__content {
      text-decoration: none;
      color: #154ba0;
    }
  }

  &.euiTab-isSelected {
    box-shadow: none;
  }

  &.euiTab-isSelected {
    background-color: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &.euiTab-isSelected .euiTab__content {
    color: #154ba0 !important;
  }

  &.euiTab-isSelected:hover,
  &.euiTab-isSelected:focus {
    text-decoration: none;
  }
}

.euiTab + .euiTab {
  margin-left: 0;
}

// Table
.euiSearchBar__searchHolder {
  .euiFormControlLayout {
    height: 3rem;
  }

  .euiFieldSearch {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .euiFormControlLayoutClearButton {
    padding: 0;
    border: none;
  }
}

.euiSearchBar__filtersHolder {
  .euiFilterGroup {
    box-shadow: none;
    border-radius: 0.5rem;
    background-color: transparent;

    > .euiPopover {
      background-color: #f1f2f6;
      border: 0.1px solid #dadfee;

      @media (min-width: 75rem) {
        margin-right: 0.5rem;
        border-radius: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .euiPopover__anchor {
        border-radius: 0.5rem;
        overflow: hidden;

        svg {
          color: #727eaa;
        }
      }
    }
  }

  .euiFilterButton {
    height: 3rem;
    box-shadow: none;

    .euiFilterButton__textShift {
      color: #727eaa;
    }
  }
}

.euiPopover__panel {
  background-color: #f1f2f6 !important;

  div[data-popover-arrow='top']::before {
    border-block-start-color: #1f1f1f !important;
  }

  div[data-popover-arrow='bottom']::before {
    border-block-end-color: #1f1f1f !important;
  }

  div[data-popover-arrow='left']::before {
    border-inline-start-color: #1f1f1f !important;
  }

  div[data-popover-arrow='right']::before {
    border-inline-start-color: #1f1f1f !important;
  }

  .euiContextMenuItem {
    background-color: #1f1f1f;
    border: none;
    cursor: pointer;
    font-family: Poppins, san-serif;
    font-size: 0.875rem;
  }
}

.euiSelectableListItem__icon {
  color: #727eaa !important;
}

.euiSelectableListItem__content {
  svg {
    color: #727eaa;
  }
}

.euiSelectableList__list {
  mask-image: none;
}

.euiSelectableListItem__text {
  .euiHealth {
    font-size: 0.875rem !important;
    color: #154ba0;
    vertical-align: middle;
  }

  .networkInfo {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    img {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    p {
      font-weight: 400;
    }
  }
}

.euiSelectableListItem:not(:last-of-type) {
  border: 0.1px solid #dadfee;
}

.euiSelectableListItem__text .networkInfo p {
  color: #154ba0;
}

.euiSelectableListItem__text--truncate {
  color: #154ba0;
}

.euiSelectableListItem-isFocused:not([aria-disabled='true']),
.euiSelectableListItem:hover:not([aria-disabled='true']) {
  color: #4bc9f0;

  .euiSelectableListItem__text {
    text-decoration: none;
  }

  .networkInfo p {
    color: #4bc9f0;
  }
}

// Table
.euiTableHeaderMobile {
  padding-top: 0;

  > .euiFlexGroup > .euiFlexItem {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.euiTableSortMobile .euiButtonEmpty {
  height: 3rem;
  border: none;

  &:focus {
    background-color: transparent;
  }
}

.euiTableSortMobileItem {
  font-family: Poppins, sans-serif;
  background-color: #1f1f1f;
  border: none;
}

.euiTable {
  background-color: transparent;

  @media (min-width: 48rem) {
    thead tr {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }
}

.euiTable.euiTable--responsive thead {
  @media (max-width: 1199px) {
    display: none;
  }
}

.euiTable.euiTable--responsive .euiTableRow {
  background-color: #f1f2f6;
  border: 0.1px solid #dadfee;
  border-radius: 0.5rem;

  @media (min-width: 48rem) {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1199px) {
    &.euiTableRow-isExpandable,
    &.euiTableRow-hasActions {
      background-size: 40px 100%;
      background-position-x: right;
      background-repeat: no-repeat;
      padding-right: 40px;
      position: relative;
    }

    .euiTableRowCell--isExpander {
      min-width: 0;
      width: 24px;
      position: absolute;
      top: 16px;
      right: 8px;
    }
  }

  &:hover {
    background-color: #f1f2f6;
    border: 0.1px solid #dadfee;
  }

  &.euiTableRow-isExpandable,
  &.euiTableRow-hasActions {
    background-image: none;
    box-shadow: none;
  }

  &.euiTableRow-isExpandedRow {
    background-image: none;

    @media (min-width: 48rem) {
      margin-top: -0.5rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    
    .euiTableRowCell {
      width: 100%;
    }
  }

  &[aria-owns*='row_'] {
    @media (min-width: 48rem) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .euiTableRowCell--hasActions .euiTableCellContent .euiButtonEmpty {
    border: none;

    .euiButtonContent {
      padding: 0;
    }
  }

  .euiTableRowCell--isExpander .euiButtonIcon {
    background-color: transparent;
    color: #727eaa;
  }
}

.euiTableHeaderCell {
  .euiTableCellContent__text {
    text-transform: uppercase;
    font-family: Poppins, san-serif;
    font-size: 0.75rem;
    font-weight: 600;
    color: #154ba0;
  }

  .euiTableHeaderButton {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .euiTableHeaderButton-isSorted .euiTableSortIcon {
    fill: #727eaa;
  }
}

.euiTableRowCell {
  border-top: 0;
  border-bottom: 0;

  @media (min-width: 48rem) {
    display: block;
  }

  @media (max-width: 1199px) {
    .euiTableRowCell__mobileHeader.euiTableRowCell--hideForDesktop {
      display: block !important;
      max-width: 100%;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      word-wrap: normal !important;
      font-size: 9.625px;
      font-size: 0.6875rem;
      display: block;
      color: #154ba0;
      padding: 8px;
      padding-bottom: 0;
      margin-bottom: -8px;
      min-height: 24px;
    }
  }

  @media (max-width: 1199px) {
    display: block;
    min-width: 33.33%;
    border: none;
  }

  &.euiTableRowCell--middle {
    align-self: center;
  }
}

.euiTableCellContent {
  @media (min-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .euiHealth {
    color: #154ba0;
    vertical-align: middle;
  }
}

// Table Rows per page
.euiButtonEmpty[data-test-subj='tablePaginationPopoverButton'] {
  height: auto;
  border: 0;
  padding: 0;
  font-size: 0.875rem;
  color: #154ba0;

  .euiButtonContent {
    padding: 0;
  }

  &:focus {
    background-color: transparent;
  }
}

// Pagination
.euiPagination__list {
  margin: 0 !important;

  .euiButtonEmpty {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border: 0;
    text-align: center;
    text-decoration: none;
    font-size: 0.875rem;
    color: #353945;
    margin: 0 0.25rem;

    &:hover {
      color: #fff;
      background-color: rgba($color: #154ba0, $alpha: 0.5);
    }
  }

  .euiButtonContent {
    padding: 0;
    font-weight: 600;
  }

  .euiPaginationButton-isActive {
    color: #fff;
    background-color: rgba($color: #154ba0, $alpha: 1);
  }
}

.euiPaginationArrowButton:not([class*='isDisabled']):hover,
.euiPaginationArrowButton:not([class*='isDisabled']):focus,
.euiPaginationArrowButton:not([class*='isDisabled']):focus-within {
  background-color: rgba($color: #154ba0, $alpha: 1);
  transform: none;
  color: #fff;
}

// Search on Filters
.euiPopoverTitle {
  border-bottom: none;
  border-block-end: none !important;

  .euiFormControlLayout {
    height: 40px;
    border: none
  }

  .euiFieldSearch {
    height: 40px;
  }

  .euiFormControlLayoutIcons {
    height: 38px;
  }
}

.eui-yScroll::-webkit-scrollbar {
  width: 4px;
}

.eui-yScroll::-webkit-scrollbar-thumb {
  border: 0;
}
.euiSwitch {
  padding-top: 1.5rem;
  margin-left: 0.5rem;
  margin-bottom: -1rem;
  display: flex;
  align-items: center;
}
.euiSwitch__button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__body {
  height: 24px;
  width: 46px;
}
.euiSwitch .euiSwitch__body {
  background-color: #154ba0;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__thumb {
  margin-top: 2px;
  margin-left: 4px;
  height: 18px;
  width: 18px;
  left: 20px;
  border: none;
  background-color: #fff;
}
.euiSwitch:hover .euiSwitch__button:active .euiSwitch__thumb {
  transform: scale(1.00);
}
.euiSwitch .euiSwitch__label {
  color: #154ba0;
}
.euiFormControlLayout {
  background-color: #fff;
}
.euiFieldSearch + .euiFormControlLayoutIcons {
  color: #727eaa;
}
.euiFieldSearch:focus {
  background-color: rgba(255, 255, 255, 0);
  color: #727eaa;
}
.euiSpacer {
  background-color: #fff;
}
.euiToolTipAnchor {
  color: #727eaa;
}